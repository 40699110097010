<template>
  <div id="search">
    <portal to="filter">
      <el-header class="filter-header special-filter-header">
        <div class="title">Search</div>
        <div class="actions-buttons"></div>
      </el-header>
      <div class="search-input search-top">
        <el-col :span="11">
          <el-input placeholder="Search" class="search-page-input" prefix-icon="icon-search" v-model="filter.SearchTerm" clearable @clear="filter.SearchTerm = null"></el-input>
        </el-col>

        <el-button @click="openNav()" class="btn btn-search" type="info" icon="icon-pen-to-square-solid"> Notes</el-button>
      </div>
    </portal>
    <div class="no-search" v-if="potentialCustomersList.length == 0 && customersList.length == 0">
      <img src="../assets/img/nosearch.png" alt="" />
    </div>
    <div class="tables" v-else>
      <div class="table-container" v-if="customersList.length > 0">
        <div class="view-info">
          <div class="table-info">Customer</div>
          <div class="table-totals">
            <!-- <p>You are viewing page {{ filter.Page }} out of a total of {{ totalCount.PageCount }} pages. (Total Records: {{ totalCount.TotalCount }})</p> -->
            <el-button class="refresh-btn" @click="arrowsRotate1"> <i class="icon-arrows-rotate-solid"></i></el-button>
          </div>
        </div>
        <el-table v-if="customersList" :data="customersList" style="width: 100%">
          <el-table-column prop="AccountNo" label="Account Number" width="180"> </el-table-column>
          <el-table-column prop="CompanyName" label="Company"> </el-table-column>

          <el-table-column prop="name" label="Name">
            <template slot-scope="scope"> {{ scope.row.FirstName }} {{ scope.row.LastName }} </template>
          </el-table-column>
          <el-table-column label="Account Manager">
            <template slot-scope="scope">
              <span v-if="scope.row.AccountManagerId != null"> {{ getUserList.find((x) => x.ID == scope.row.AccountManagerId).Title }}</span>
              <span v-else> No account manager yet </span>
            </template>
          </el-table-column>

          <el-table-column label="Location" v-if="getAllCountries">
            <template slot-scope="scope">
              <span v-if="getAllCountries.some((x) => x.country_id == scope.row.CountryId)">{{ getAllCountries.find((x) => x.country_id == scope.row.CountryId).cities.find((x) => x.city_id == scope.row.CityId).name }}, </span>
              <span v-if="getAllCountries.some((x) => x.country_id == scope.row.CountryId)">{{ getAllCountries.find((x) => x.country_id == scope.row.CountryId).country }} </span>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="Date">
            <template slot-scope="scope">
              <span>Created Date : {{ $moment(scope.row.CreatedDateTime).format("DD-MM-YYYY") }}</span
              ><br />
              <span>Last Update : {{ $moment(scope.row.UpdatedDateTime).format("DD-MM-YYYY") }}</span>
            </template>
          </el-table-column>
          <el-table-column width="10">

          </el-table-column>
          <!-- <el-table-column label="Actions" width="160" v-if="user.Role == 10 || user.Role == 1 || user.Role == 5">
            <template slot-scope="scope">
              <el-dropdown>
                <el-button type="primary"> Actions<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span class="view-edit" @click="openAddDialog(scope.row.ID)"><i class="icon-pen-to-square-solid"></i> View & Edit</span>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <span class="delete-item" @click="deleteFile(scope.row.ID)"><i class="icon-trash-can-solid"></i>Delete Item</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column> -->

          <template slot="empty">
            <div class="no-data-text">No Records Found.</div>
          </template>
        </el-table>
      </div>
      <div class="table-container" v-if="potentialCustomersList.length > 0">
        <div class="view-info">
          <div class="table-info">Potential Customer</div>
          <div class="table-totals">
            <!-- <p>You are viewing page {{ filter.Page }} out of a total of {{ totalCount.PageCount }} pages. (Total Records: {{ totalCount.TotalCount }})</p> -->
            <el-button class="refresh-btn" @click="arrowsRotate2"> <i class="icon-arrows-rotate-solid"></i></el-button>
          </div>
        </div>
        <el-table v-if="potentialCustomersList" :data="potentialCustomersList" style="width: 100%">
          <el-table-column prop="LeadId" label="Lead ID" width="180"> </el-table-column>
          <el-table-column prop="CompanyName" label="Company"> </el-table-column>
          <el-table-column prop="name" label="Name">
            <template slot-scope="scope"> {{ scope.row.FirstName }} {{ scope.row.LastName }} </template>
          </el-table-column>
          <el-table-column label="Account Manager">
            <template slot-scope="scope">
              <span v-if="scope.row.AccountManagerId != null"> {{ getUserList.find((x) => x.ID == scope.row.AccountManagerId).Title }}</span>
              <span v-else> No account manager yet </span>
            </template>
          </el-table-column>
          <el-table-column label="Location" v-if="getAllCountries">
            <template slot-scope="scope">
              <span v-if="getAllCountries.some((x) => x.country_id == scope.row.CountryId)">{{ getAllCountries.find((x) => x.country_id == scope.row.CountryId).cities.find((x) => x.city_id == scope.row.CityId).name }}, </span>
              <span v-if="getAllCountries.some((x) => x.country_id == scope.row.CountryId)">{{ getAllCountries.find((x) => x.country_id == scope.row.CountryId).country }} </span>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="Date">
            <template slot-scope="scope">
              <span>Created Date : {{ $moment(scope.row.CreatedDateTime).format("DD-MM-YYYY") }}</span>
              <br />
              <span>Last Update : {{ $moment(scope.row.UpdatedDateTime).format("DD-MM-YYYY") }}</span>
            </template>
          </el-table-column>
          <el-table-column width="10">
          </el-table-column>
          <!-- <el-table-column label="Actions" width="160" v-if="user.Role == 10 || user.Role == 1 || user.Role == 5">
            <template slot-scope="scope">
              <el-dropdown>
                <el-button type="primary"> Actions<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span class="view-edit" @click="openAddDialogPotentialCustomer(scope.row)"><i class="icon-pen-to-square-solid"></i> View & Edit</span>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <span class="delete-item" @click="deleteFilePotentialCustomer(scope.row.ID)"><i class="icon-trash-can-solid"></i>Delete Item</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column> -->

          <template slot="empty">
            <div class="no-data-text">No Records Found.</div>
          </template>
        </el-table>
      </div>
    </div>
    <div id="mySidenav" class="sidenav">
      <div class="sidenav-top">
        <div class="top-title">Notes<span class="yellow-dot">.</span></div>
        <el-button class="btn close" type="danger" @click="closeNav()"> <i class="icon-xmark-solid"></i></el-button>
      </div>
      <div class="all-notes">
        <div class="note" v-for="(item, index) in getAllNotes" :key="index">
          <div class="delete-note">
            <span class="delete-item" @click="deleteNote(item.ID)"><i class="icon-trash-can-solid"></i></span>
          </div>
          <div class="note-msg">
            {{ item.Title }}
          </div>
          <div class="bottom">
            <div class="updates-bottom">
              <div class="bottom">
                <div class="circle">{{ item.UserMini.FirstName[0] + "" + item.UserMini.LastName[0] }}</div>
                <div class="user">
                  <div class="user-info">{{ item.UserMini.FullName }}</div>
                  <div class="status">
                    <span>{{ getEnumsDisplay("TypeOfAuthority", item.UserMini.Role) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="note-date">
              <div class="day">{{ $moment(item.CreatedDateTime).format("DD-MM-YYYY") }}</div>
              <div class="hour">{{ $moment(item.CreatedDateTime).format("LT") }}</div>
            </div>
          </div>
          <div class="line"></div>
        </div>
      </div>
      <div class="textarea">
        <Mentionable :keys="['@']" :items="items" offset="6" @apply="onApply(...arguments)">
          <textarea style="width: 97%" v-model="noteText" :rows="4" placeholder="Add a Comment.." />
        </Mentionable>
        <div class="save-btn">
          <el-button type="primary" @click="saveNote()">Save</el-button>
        </div>
      </div>
    </div>

    <el-dialog custom-class="add-potential-customer" top="5vh" :close-on-click-modal="false" :visible.sync="potentialDialogVisible" :show-close="false">
      <addOrUpdatePotentitalCustomers :form="setForm" @close="closeAddUpdatePotentitalCustomers()" />
    </el-dialog>
    <el-dialog top="5vh" :visible.sync="dialogVisible" :show-close="false" :close-on-click-modal="false" custom-class="add-potential-customer">
      <addOrUpdateCustomersDialog :id="setCustomerId" @close="closeAddDialog()" />
    </el-dialog>
  </div>
</template>

<script>
import addOrUpdatePotentitalCustomers from "../components/addOrUpdatePotentitalCustomers.vue";
import addOrUpdateCustomersDialog from "../components/addOrUpdateCustomersDialog";
import { Mentionable } from "vue-mention";

export default {
  components: {
    addOrUpdatePotentitalCustomers,
    addOrUpdateCustomersDialog,
    Mentionable,
  },
  data() {
    return {
      items: [],
      potentialDialogVisible: false,
      dialogVisible: false,
      setForm: null,
      setCustomerId: null,
      loading: false,
      timeout: null,
      hedefID: [],
      filter: {
        SearchTerm: null,
        Page: 1,
        PageSize: 999999,
      },
      totalCount: {
        TotalCount: 0,
        PageCount: 0,
      },
      noteText: null,
      dummyText: "Lorem ipsum dolor sit amet, consectetur adipiscing else    Lorem ipsum dolor sit amet, consectetur adipiscing  else Lorem ipsum d      Lorem ipsum d Lorem ipsum d Lorem ipsum d ",
    };
  },
  async beforeMount() {
    // this.$store.dispatch("getCustomersList", { Page: 1, PageSize: 999999, SearchTerm: null });
    // this.$store.dispatch("getPotentialCustomersList", { Page: 1, PageSize: 999999, SearchTerm: null });
    this.$store.dispatch("getNotesList");
    await this.$store.dispatch("getUserList", { Page: 1, PageSize: 999999, SearchTerm: null });
    await this.getUserList
      .filter((x) => x.IsDeactivated != true)
      .map((x) => {
        this.items.push({
          value: x.FirstName + x.LastName,
          label: x.FirstName + " " + x.LastName,
          id: x.ID,
        });
      });
  },

  computed: {
    getEnums() {
      return this.$store.getters.getEnums;
    },
    user() {
      return this.$store.getters.getUser;
    },
    getUserList() {
      return this.$store.getters.getUserList;
    },
    customersList() {
      return this.$store.getters.getCustomersList;
    },
    potentialCustomersList() {
      return this.$store.getters.getPotentialCustomersList;
    },
    getAllCountries() {
      return this.$store.getters.getAllCountries;
    },
    getAllNotes() {
      return this.$store.getters.getNotes;
    },
  },
  methods: {
    openNav() {
      document.getElementById("mySidenav").style.width = "512px";
    },

    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    },
    onApply(...args) {
      this.hedefID.push(args[0].id);
    },
    async saveNote() {
      var payload = {
        Note: this.noteText,
        NotificationOwnerIds: this.hedefID,
      };
      await this.$store.dispatch("addNotes", payload);

      this.noteText = null;
      this.hedefID = [];
    },
    deleteNote(index) {
      this.$store.dispatch("deleteOneNote", index);
    },

    arrowsRotate1() {
      this.$store.dispatch("getCustomersList");
    },
    arrowsRotate2() {
      this.$store.dispatch("getPotentialCustomersList");
    },

    async getCustomers() {
      await this.$store.dispatch("getCustomersList", this.filter);
    },
    async getPotentialCustomers() {
      await this.$store.dispatch("getPotentialCustomersList", this.filter);
    },
    openAddDialog(id) {
      if (id == null) {
        this.loading = "addButton";
        this.setCustomerId = null;
      } else {
        this.setCustomerId = id;
      }
      this.dialogVisible = true;
      this.loading = false;
    },
    closeAddDialog() {
      this.dialogVisible = false;
    },
    openAddDialogPotentialCustomer(form) {
      if (form == null) {
        this.setForm = JSON.parse(JSON.stringify(this.form));
      } else {
        this.setForm = JSON.parse(JSON.stringify(form));
      }
      this.potentialDialogVisible = true;
    },
    closeAddUpdatePotentitalCustomers() {
      this.potentialDialogVisible = false;
    },
    mahmut() {
      this.filter.SearchTerm = null;
    },
  },
  watch: {
    filter: {
      deep: true,
      handler: async function () {
        if (this.timeout != null) clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
          if (this.filter.SearchTerm == "" || this.filter.SearchTerm == null) {
            this.filter.SearchTerm = null;
            this.$store.commit("setCustomersList", []);
            this.$store.commit("setPotentialCustomersList", []);
          } else {

            await this.getCustomers();
            await this.getPotentialCustomers();
          }
        }, 500);
      },
    },
  },
};
</script>

<style></style>
